import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliates, deleteAffiliate } from "../../redux/apiCalls";

export default function WarrantyList() {
    const dispatch = useDispatch();
    const affiliates = useSelector((state) =>
        state.affiliate ? state.affiliate.affiliate : undefined
    );
    const currentUser = useSelector((state) => state.user.currentUser);
    const [isLoaded, setIsLoaded] = useState(false);

 const principle = useSelector((state) =>

        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const filterCategory = useState(() => {
        if (superadmin) return undefined;
        if (principle) return currentUser._id;
        return currentUser._id;
    })[0];

    const handleDelete = (id) => {
        deleteAffiliate(id, dispatch);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getAffiliates(dispatch, filterCategory);
            if (res) {
                setIsLoaded(true);
            }
        };
        fetchData();
    }, [dispatch, filterCategory]);

    const columns = [
        { field: "code", headerName: "Promo Code", width: 200, sortable: false },
        {
            field: "userId",
            headerName: "User",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="">
                    {value ? `${value.name}` : ""}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <DeleteOutline
                            className="productListDelete"
                            onClick={() => handleDelete(params.row._id)}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            {isLoaded === true && (
                <div className="productList">
                    <DataGrid
                        rows={affiliates}
                        disableSelectionOnClick
                        columns={columns}
                        getRowId={(row) => row._id}
                        pageSize={20}
                        disableColumnMenu
                    />
                </div>
            )}
        </>
    );
}
