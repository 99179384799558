import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";
import _ from "lodash";

const createCustomerInvoice = async (original) => {
    try {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let addressArray = [];

        for (let i = 0; i < original.address.length; i += 60) {
            addressArray.push(original.address.substring(i, i + 60));
        }

        const totalPages =
            original.products.length <= 10
                ? 1
                : Math.ceil((original.products.length - 10) / 10 + 1);

        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        const timesRomanBoldFont = await pdfDoc.embedFont(
            StandardFonts.TimesRomanBold
        );

        // Get the width and height of the page

        // Add a blank page to the document
        for (let i = 0; i < totalPages; i++) {
            // Add a blank page to the document
            const page = pdfDoc.addPage();
            const fontSize = 20;
            const { height } = page.getSize();
            let totalAmount = 0;

            for (const product of original.products) {
                totalAmount = totalAmount + product.price * product.amount;
            }

            if (i === totalPages - 1) {
                const pageData = original.products.slice(i * 10, (i + 1) * 10);
                const { height } = page.getSize();

                page.drawText(`AIRSPHERE SOLUTION`, {
                    x: 25,
                    y: height - 1.9 * fontSize,
                    size: 22,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`202503051042 (003702515-W)`, {
                    x: 25,
                    y: height - 2.7 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Bank Account:`, {
                    x: 25,
                    y: height - 4.1 * fontSize,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Maybank 5148 9713 3467`, {
                    x: 25,
                    y: height - 4.8 * fontSize,
                    size: 13,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`T`, {
                    x: 485,
                    y: height - 3.4 * fontSize,
                    size: 11,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`+6 011 6302 8626`, {
                    x: 495,
                    y: height - 3.4 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`E`, {
                    x: 433,
                    y: height - 4.1 * fontSize,
                    size: 11,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`airspheresolution@gmail.com`, {
                    x: 443,
                    y: height - 4.1 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`W`, {
                    x: 455,
                    y: height - 4.8 * fontSize,
                    size: 11,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`www.airvitamin.com.my`, {
                    x: 465,
                    y: height - 4.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 20,
                        y: height - 5.5 * fontSize,
                    },
                    end: {
                        x: 570,
                        y: height - 5.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Invoice ID:`, {
                    x: 25,
                    y: height - 7.5 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${_.padStart(original.invoiceId, 4, "0")}`, {
                    x: 140,
                    y: height - 7.5 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date of Issue:`, {
                    x: 25,
                    y: height - 8.2 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${moment(original.createdAt).format("DD MMM YYYY")}`,
                    {
                        x: 140,
                        y: height - 8.2 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Accounts Name:`, {
                    x: 25,
                    y: height - 8.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.name}`, {
                    x: 140,
                    y: height - 8.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Phone Number:`, {
                    x: 25,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.phoneNumber}`, {
                    x: 140,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Email:`, {
                    x: 25,
                    y: height - 10.3 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.email}`, {
                    x: 140,
                    y: height - 10.3 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Address:`, {
                    x: 25,
                    y: height - 11 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                if (original.userId.address) {
                    page.drawText(
                        `${original.userId.address.lineOne}${original.userId.address.lineTwo}`,
                        {
                            x: 140,
                            y: height - 11 * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );

                    page.drawText(
                        `${original.userId.address.zip}, ${original.userId.address.city}, ${original.userId.address.country}`,
                        {
                            x: 140,
                            y: height - 11.7 * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                } else {
                    page.drawText(`-`, {
                        x: 140,
                        y: height - 11 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                }

                page.drawText("No", {
                    x: 40,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Item", {
                    x: 80,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Price (RM)", {
                    x: 480,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - 14.2 * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - 14.2 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${index + 1 + i * 10}`, {
                        x: 40,
                        y: height - (15.7 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                        weight: 600,
                    });

                    page.drawText(`${product.code}`, {
                        x: 80,
                        y: height - (15.7 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${NumberFormatter.format(
                            product.price * product.quantity
                        )}`,
                        {
                            x: 485,
                            y: height - (15.7 + index) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - (16.3 + pageData.length) * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - (16.3 + pageData.length) * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`SUB TOTAL:`, {
                    x: 350,
                    y: height - (17.3 + pageData.length) * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${
                        process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"
                    } ${NumberFormatter.format(totalAmount)}`,
                    {
                        x: 480,
                        y: height - (17.3 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                if (original.discount !== 0) {
                    page.drawText(`DISCOUNT:`, {
                        x: 350,
                        y: height - (18 + pageData.length) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(original.discount)}`,
                        {
                            x: 480,
                            y: height - (18 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }

                if (original.credit) {
                    page.drawText(`TRADE IN:`, {
                        x: 350,
                        y: height - (19 + pageData.length) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(original.credit.credit)}`,
                        {
                            x: 480,
                            y: height - (19 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }

                page.drawText(`TOTAL:`, {
                    x: 350,
                    y: height - (20 + pageData.length) * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${
                        process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"
                    } ${NumberFormatter.format(
                        totalAmount -
                            original.discount -
                            (original.credit ? original.credit.credit : 0)
                    )}`,
                    {
                        x: 480,
                        y: height - (20 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                if (original.deposit && original.status !== "completed") {
                    page.drawText(`DEPOSIT:`, {
                        x: 350,
                        y: height - (21 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(original.deposit)}`,
                        {
                            x: 480,
                            y: height - (21 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );

                    page.drawText(`REMAINING:`, {
                        x: 350,
                        y: height - (22 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(
                            totalAmount - original.discount - original.deposit
                        )}`,
                        {
                            x: 480,
                            y: height - (22 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - 32 * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - 32 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 40,
                        y: height - 38.75 * fontSize,
                    },
                    end: {
                        x: 220,
                        y: height - 38.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Authorised Signature", {
                    x: 50,
                    y: height - 39.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Date :", {
                    x: 50,
                    y: height - 40 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 360,
                        y: height - 38.75 * fontSize,
                    },
                    end: {
                        x: 540,
                        y: height - 38.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Customer's Signature", {
                    x: 370,
                    y: height - 39.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Date :", {
                    x: 370,
                    y: height - 40 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });
            } else {
                const pageData = original.products.slice(
                    10 + (i - 1) * 10,
                    10 + i * 10
                );

                page.drawText(`AIRSPHERE SOLUTION`, {
                    x: 25,
                    y: height - 1.9 * fontSize,
                    size: 22,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`202503051042 (003702515-W)`, {
                    x: 25,
                    y: height - 2.7 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Bank Account:`, {
                    x: 25,
                    y: height - 4.1 * fontSize,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Maybank 5148 9713 3467`, {
                    x: 25,
                    y: height - 4.8 * fontSize,
                    size: 13,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`T`, {
                    x: 485,
                    y: height - 3.4 * fontSize,
                    size: 11,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`+6 011 6302 8626`, {
                    x: 495,
                    y: height - 3.4 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`E`, {
                    x: 433,
                    y: height - 4.1 * fontSize,
                    size: 11,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`airspheresolution@gmail.com`, {
                    x: 443,
                    y: height - 4.1 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`W`, {
                    x: 455,
                    y: height - 4.8 * fontSize,
                    size: 11,
                    font: timesRomanBoldFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`www.airvitamin.com.my`, {
                    x: 465,
                    y: height - 4.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 20,
                        y: height - 5.5 * fontSize,
                    },
                    end: {
                        x: 570,
                        y: height - 5.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Invoice ID:`, {
                    x: 25,
                    y: height - 7.5 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${_.padStart(original.invoiceId, 4, "0")}`, {
                    x: 140,
                    y: height - 7.5 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date of Issue:`, {
                    x: 25,
                    y: height - 8.2 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${moment(original.createdAt).format("DD MMM YYYY")}`,
                    {
                        x: 140,
                        y: height - 8.2 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Accounts Name:`, {
                    x: 25,
                    y: height - 8.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.name}`, {
                    x: 140,
                    y: height - 8.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Phone Number:`, {
                    x: 25,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.phoneNumber}`, {
                    x: 140,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Email:`, {
                    x: 25,
                    y: height - 10.3 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.email}`, {
                    x: 140,
                    y: height - 10.3 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Address:`, {
                    x: 25,
                    y: height - 11 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                if (original.userId.address) {
                    page.drawText(
                        `${original.userId.address.lineOne}${original.userId.address.lineTwo}`,
                        {
                            x: 140,
                            y: height - 11 * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );

                    page.drawText(
                        `${original.userId.address.zip}, ${original.userId.address.city}, ${original.userId.address.country}`,
                        {
                            x: 140,
                            y: height - 11.7 * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                } else {
                    page.drawText(`-`, {
                        x: 140,
                        y: height - 11 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                }

                page.drawText("No", {
                    x: 40,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Item", {
                    x: 80,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Price (RM)", {
                    x: 480,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - 14.2 * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - 14.2 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${index + 1 + i * 10}`, {
                        x: 40,
                        y: height - (15.7 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                        weight: 600,
                    });

                    page.drawText(`${product.code}`, {
                        x: 80,
                        y: height - (15.7 + index) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${NumberFormatter.format(
                            product.price * product.quantity
                        )}`,
                        {
                            x: 485,
                            y: height - (15.7 + index) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - (16.3 + pageData.length) * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - (16.3 + pageData.length) * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });
            }
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(
            pdfBytes,
            `${original.invoiceId}-${original.userId.name}`,
            "application/pdf"
        );
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default createCustomerInvoice;
