import { useState, useEffect } from "react";
import "./newUser.css";
import { registerStaff } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import { useSelector } from "react-redux";


export default function NewUser() {
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const currentUser = useSelector((state) => state.user.currentUser);

    const [inputs, setInputs] = useState({
        outlet: superadmin ? undefined : currentUser._id
    });
    const [usersData, setUsersData] = useState([]);

    const dispatch = useDispatch();

     

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleClick = async (e) => {
        const user = { ...inputs };
        e.preventDefault();
        try {
            registerStaff({ user });
        } catch (err) {
            console.log(err);
        }
    }

    const handleInputUserId = (e) => {
        setInputs((prev) => {
            return { ...prev, outlet: e.value };
        });
    };


        const getAdmins = async () => {
            const res = await publicRequest.get("/users/admin", {
                params: {},
            });
            setUsersData(res.data);
        };

        useEffect(() => {
            const fetchData = async () => {
               await getAdmins(dispatch, []);
            };
            fetchData();
        }, [dispatch]);

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New Staff</h1>
                <div className="addProductForm">
              {superadmin && (
                  <div className="addProductItem fullWidth">
                  <label>Outlets</label>
                  <Select
                      options={usersData.map((user, i) => {
                          return {
                              label: `${user.name}`,
                              value: user._id,
                          };
                      })}
                      onChange={handleInputUserId}
                  />
              </div>
              )}
                    <div className="addProductItem">
                        <label className="black required">Full Name</label>
                        <input
                            name="name"
                            type="text"
                            placeholder="Name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Email</label>
                        <input
                            name="email"
                            type="text"
                            placeholder="Email"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Phone Number</label>
                        <input
                            name="phoneNumber"
                            type="text"
                            placeholder="Phone Number"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Password</label>
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                        />
                    </div>
                    <button onClick={handleClick} className="updateProductButton">
                        Create
                    </button>
                </div>
            </div>
        </>
    );
}
