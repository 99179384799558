import Table from "../../components/advancedTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getCommissions } from "../../redux/apiCalls";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import excel from "../../components/excel/excel";

const statusOptions = [
    {
        label: "Deposit",
        value: "deposit",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Void",
        value: "void",
    },
    {
        label: "Warranty",
        value: "warranty",
    },
    {
        label: "All",
        value: "",
    },
];



export default function CommissionList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const currentUser = useSelector((state) => state.user.currentUser);

    const [inputs, setInputs] = useState({
        user: principle
            ? principle
            : superadmin 
            ? undefined
            : currentUser._id,
        agent: principle ? currentUser._id : superadmin ? undefined : undefined,
        status: "pending",
        limit: 9999,
        page: 1,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
    });

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const [ordersData, setOrdersData] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCommissions(dispatch, inputs);
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [dispatch, inputs]);
    

    useEffect(() => {
        if (usersData[0] === undefined) {
            if (process.env.REACT_APP_COUNTRY === "SINGAPORE") {
                getAdmins();
            } else {
                getAllAdmins();
            }
        }
    }, [usersData]);


    const handleChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const agentColumns = [
        {
            accessor: "outlet",
            Header: "Dealer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : "-"}</div>
            ),
        },
        {
            accessor: "user",
            Header: "Sub-Dealer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : "-"}</div>
            ),
        },
        {
            accessor: "totalUnits",
            Header: "Total Units",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value : "-"}</div>
            ),
        },
        {
            accessor: "totalSales",
            Header: "Total Sales",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? `RM ${formatter.format(value)}` : "-"}</div>
            ),
        },
        {
            accessor: "commission",
            Header: "Commission",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? `RM ${formatter.format(value)}` : "-"}</div>
            ),
        },
    ];

    const columns = [
        {
            accessor: "outlet",
            Header: "Dealer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : "-"}</div>
            ),
        },
        {
            accessor: "user",
            Header: "Sub-Dealer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : "-"}</div>
            ),
        },
        {
            accessor: "totalUnits",
            Header: "Total Units",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value : "-"}</div>
            ),
        },
        {
            accessor: "totalSales",
            Header: "Total Sales",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? `RM ${formatter.format(value)}` : "-"}</div>
            ),
        },
        {
            accessor: "principleCommission",
            Header: "Commission",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? `RM ${formatter.format(value)}` : "-"}</div>
            ),
        },
        {
            accessor: "commission",
            Header: "Sub Dealer Commission",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? `RM ${formatter.format(value)}` : "-"}</div>
            ),
        },
       
    ];

    return (
        <>
        <div className="productList">
            <div className="samerow">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="dateFrom"
                        value={inputs.dateFrom}
                        type="date"
                        onChange={(e) => handleDateFrom(e)}
                    />
                </div>
                <div className="addProductItem ">
                    <input
                        className="inputFilter"
                        name="dateTo"
                        value={inputs.dateTo}
                        type="date"
                        onChange={(e) => handleDateTo(e)}
                    />
                </div>
                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleInputStatus}
                        placeholder={inputs.status}
                        onClick={() =>
                            usersData[0] === undefined
                                ? getAdmins()
                                : {}
                        }
                    />
                </div>
                <div className="sameRowItem marginRight">
                {superadmin && (
                    <Select
                        options={usersData.map((user, i) => {
                            return {
                                label: `${user.name}`,
                                value: user._id,
                            };
                        })}
                        onChange={handleChange}
                        placeholder={"Dealer"}
                        onClick={() =>
                            usersData[0] === undefined
                                ? process.env.REACT_APP_COUNTRY ===
                                  "SINGAPORE"
                                    ? getAdmins()
                                    : getAllAdmins()
                                : {}
                        }
                    />
                )}
            </div>
            </div>

            <div className="block">
                {superadmin && (
                    <button
                        onClick={() => {
                            let payload = [];
                            ordersData.forEach(
                                (order, i) => {
                                    payload.push({
                                        No: i + 1,
                                        Outlet: order.user
                                            .name,
                                        TotalUnits: order.totalUnits,
                                        TotalSales: `RM ${formatter.format(order.totalSales)}`,
                                        CommissionPercentage: `${formatter.format(order.commissionPercentage)} %`,
                                        Commission: `RM ${formatter.format(order.commission)}`,
                                        Incentive: `RM ${formatter.format(order.incentive)}`,
                                        TotalCommission: `RM ${formatter.format(order.totalCommission)}`,
                                    });
                                }
                            );
                            excel({
                                excelData: payload,
                                fileName: `Haofa-Orders-${moment(
                                    inputs.dateFrom
                                ).format("DD-MM-YYYY")}-to-${moment(
                                    inputs.dateTo
                                ).format("DD-MM-YYYY")}`,
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                )}
            </div>
           
                <Table
                    data={ordersData ? ordersData : []}
                    columns={principle ? agentColumns : columns}
                    pageCount={
                        1
                    }
                    totalCount={1}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                />
        </div>




    </>
    );
}
