import "./history.css";
import { DataGrid } from "@material-ui/data-grid";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistory, voidOrder } from "../../redux/apiCalls";
import moment from "moment";
import Select from "react-select";
import { publicRequest } from "../../requestMethods";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const orders = useSelector((state) => state.order.order);
    const currentUser = useSelector((state) => state.user.currentUser);

    const marketing = useSelector((state) =>
    state.user.currentUser
        ? state.user.currentUser.type === "marketing"
        : null
);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "principle"
            : null
    );

    const outright = useSelector((state) =>
        state.user.currentUser


            ? state.user.currentUser.type === "outright"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const [inputs, setInputs] = useState({
        createdBy: superadmin ? undefined : principle?._id || currentUser?._id,
        cashier: superadmin ? undefined : outright ? undefined :  currentUser?._id,
        user: superadmin || manager || accountant || marketing ? undefined : currentUser._id,
        limit: 20,
        page: 1,
    });
    const [statusMenu, setStatusMenu] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        async function fetchData() {
          const result = await getHistory(dispatch, inputs);

          setHistoryData(result.payload);
        }
        
        fetchData();
      }, [dispatch, inputs]);
      

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    const handleVoid = (id) => {
        voidOrder(id, dispatch);
    };

    const handleUserFilter = (e) => {
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    const fetchOrders = async (dispatch, inputs) => {
        const result = await getHistory(dispatch, inputs);
        setHistoryData(result.payload)
    };

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    const columns = [
        {
            field: "product",
            headerName: "Products",
            width: 120,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon"
                            onClick={() => {
                                setModalData(params.row.products);
                                setStatusMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            field: "userId",
            headerName: "Customer",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "amount",
            headerName: "Total Paid",
            width: 200,
            sortable: false,
            renderCell: (params) => (
                <div className="initialPadding">RM{params ? formatter.format(params.row.amount - params.row.discount) : ""}</div>
            ),
        },
        {
            field: "createdBy",
            headerName: "Salesperson",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "createdAt",
            headerName: "Purchase Date",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            field: "paymentType",
            headerName: "Payment Type",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            width: 250,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {(superadmin || accountant) && (
                            <CloudDownload
                                className="pointer icon"
                                onClick={() => {
                                    createCustomerInvoice(params.row);
                                }}
                            />
                        )}
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon"
                                onClick={() => {
                                    setModalData(params.row._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="productList">
                <div className="addProductItem">
                    <Select
                        options={usersData.map((user, i) => {
                            return {
                                label: `${user.name} - ${user.phoneNumber}`,
                                value: user._id,
                            };
                        })}
                        placeholder={"Customer"}
                        onChange={handleUserFilter}
                    />
                </div>
                <DataGrid
                    rows={historyData}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => (row ? row._id : {})}
                    pageSize={20}
                    rowCount={orders.totalPages}
                    disableColumnMenu
                    paginationMode="server"
                    onPageChange={async (newPage) => {
                        inputs.page = newPage.page;
                        await fetchOrders(dispatch, inputs);
                        setInputs((prev) => ({
                            ...prev,
                            page: newPage.page,
                        }));
                    }}
                />
            </div>

            {statusMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setStatusMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="updatemodalContainer">
                            <div className="updateOrder">
                                <h1 className="updateProductTitle">
                                    Products({modalData.length})
                                </h1>
                                <div className="productContainer">
                                    {modalData.map((product) => {
                                        return (
                                            <div className="product">
                                                {" "}
                                                <div className="justify-center flex">
                                                <img
                                                    src={product.img}
                                                    className="productImg"
                                                    alt="productImages"
                                                />
                                                </div>
                                                <div className="productDetails">
                                                    {product.code}
                                                </div>
                                                <div className="productDetails">
                                                    {process.env
                                                        .REACT_APP_COUNTRY ===
                                                    "SINGAPORE"
                                                        ? "$"
                                                        : "RM"}
                                                    {product.price.toLocaleString(
                                                        "en-US"
                                                    )}
                                                    .00
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {voidMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setVoidMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Void Order?
                                </h3>
                                <div className="buttonContainer">
                                    <button
                                                                              className="confirm pointer green"
                                        onClick={() => {
                                            handleVoid(modalData);
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                      className="cancel pointer red"
                                        onClick={() => {
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
