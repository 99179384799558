import { useEffect, useState } from "react";
import { Add, Remove } from "@material-ui/icons";
import "./pos.css";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { userRequest } from "../../requestMethods";
import { publicRequest } from "../../requestMethods";
import styled from "styled-components";
import { addProduct, clearCart, addQuantityIcon } from "../../redux/cartRedux";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";
import createDeposit from "../../components/pdf/createDeposit";
import { useLocation } from "react-router";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const EmptyModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

const ColorPickerContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 101;
            background: rgb(240, 240, 255);
`;


export default function EditOrder() {
    const dispatch = useDispatch();
    const location = useLocation();
    const products = useSelector((state) => state.product.products);
    const [colorpicker, setColorPicker] = useState(false);
    // eslint-disable-next-line
    const [tradeinamount, setTradeinamount] = useState(0);
    const [cashierMode, setCashierMode] = useState("sales");
    const [usersData, setUsersData] = useState([]);
    const [brands, setBrands] = useState([]);
    const [tradeins, setTradeins] = useState([]);
    const [name, setName] = useState("");
    const [tradeinLoaded, setTradeinLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [inputs, setInputs] = useState({
        isProduct: true,
    });
    const [itemDetails, setItemDetails] = useState({
        name: undefined,
        price: undefined,
        type: undefined,
    });
   
    const [product, setProduct] = useState({});
    const [color, setColor] = useState({});
    const [filterList, setFilterList] = useState({
        type: "watch",
    });
    // eslint-disable-next-line
    const [productfilterCategory, setProductFilterCategory] = useState({
        status: "active",
        model: undefined,
        brand: undefined,
    });

    // eslint-disable-next-line
    const [quantity, setQuantity] = useState(1);

    const cart = useSelector((state) =>
        state.cart ? state.cart.products : []
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const currentUser = useSelector((state) => state.user.currentUser);

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const total = useSelector((state) =>
        state.cart ? (state.cart.total ? state.cart.total : 0) : 0
    );


    let deconstructedProductList = [];
    cart.forEach((product) => {
        for (let i = 0; i < product.quantity; i++) {
            deconstructedProductList.push(product);
        }
    });

    useEffect(() => {
        if (colorpicker === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [colorpicker]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    useEffect(() => {
        getProducts(dispatch, productfilterCategory);
    }, [dispatch, filterList, productfilterCategory]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSetItemDetails = (e) => {
        setItemDetails((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const getAllTradeIns = async () => {
        const res = await publicRequest.get("/credits/all", {
            params: { isAdmin: true },
        });
        setTradeins(res.data);
        setTradeinLoaded(true)
    };

    useEffect(() => {
        if (tradeinLoaded === false) {
            getAllTradeIns();
        }
    }, [tradeins, tradeinLoaded]);

    const getAllBrands = async () => {
        const res = await publicRequest.get("/brand/all", {
            params: { isAdmin: true },
        });
        setBrands(res.data);
    };

    useEffect(() => {
        if (brands.length === 0) {
            getAllBrands();
        }
    }, [brands]);

    const getAllCategories = async () => {
        const res = await publicRequest.get("/category/all", {
            params: { isAdmin: true },
        });
        setCategories(res.data);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getAllCategories();
        }
    }, [categories]);


    const handleQuantity = (
        product,
        quantity,
        amount,
        myAmount,
        activeColor
    ) => {
        dispatch(
            addQuantityIcon({
                ...product,
                quantity,
                amount,
                myAmount,
                activeColor,
            })
        );
    };

    const handlePosCreateOrder = async () => {
        let totalItems = 0;
        cart.forEach((item) => {
            if (item.type === "watch") {
                totalItems = totalItems + 1 * item.quantity;
            }
        });
        if (cart.length === 0) {
            alert("No product added!!");
            return;
        }

        if (total - inputs.discount < 0) {
            alert("Total cannot be a negative number");
            return;
        }

        const predata = location.state.order

        if (currentUser) {
            try {
                const res = await userRequest.put(`/orders/update/${predata._id}`, {
                    products: cart.map((item) => ({
                        productId: item._id,
                        quantity: item.amount,
                        code:
                         `${item.color.name}`,
                        img: item.img,
                        price: item.price,
                        amount: item.amount,
                    })),
                    amount: total,
                    discount: inputs.discount,
                });

                console.log(res.data)

                if (res.data) {
                    alert("Your order has been updated");
                    createCustomerInvoice(res.data);
                    dispatch(clearCart());
                } else {
                    alert("Server issue, please contact support");
                }
            } catch (err) {
                alert(
                    "Failed to create order. Please check if the warranty is correct and belongs to this outlet. Please contact admin if you need support."
                );
            }
        } else {
            alert("Please login before checking out");
        }
    };

    const handleClear = async () => {
        dispatch(clearCart());
    };

    const handleNameChange = (e) => {
        setProductFilterCategory((prev) => {
            return { ...prev, name: e.target.value };
        });

        setName(e.target.value);
    };

    const handlePosDeposit = async () => {
        let totalItems = 0;
        cart.forEach((item) => {
            if (item.type === "watch") {
                totalItems = totalItems + 1 * item.quantity;
            }
        });
        if (cart.length === 0) {
            alert("No product added!!");
            return;
        }

        if (total - inputs.discount < 0) {
            alert("Total cannot be a negative number");
            return;
        }

        if (inputs.userId === undefined) {
            alert("Please select a customer first");
            return;
        }

        if (total - inputs.deposit < 0) {
            alert("Deposit cannot be more than sales total");
            return;
        }

        if (!inputs.deposit) {
            alert(
                `Deposit cannot be less than ${
                    process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
                } 0.00`
            );
            return;
        }

        if (currentUser) {
            try {
                const createdBy = principle ? principle : currentUser._id;
                const res = await userRequest.post("/orders/deposit", {
                    userId: createdBy,
                    inputs,
                    products: cart.map((item) => ({
                        productId: item._id,
                        quantity: item.quantity,
                        code: `${item.model}-${item.color.name}`,
                        img: item.color.img,
                    })),
                    amount: total,
                    address: "POS Sales",
                    isProduct: true,
                    cashier: currentUser ? currentUser._id : undefined,
                });

                if (res.data) {
                    alert("Your deposit has been completed");
                    createDeposit(res.data);
                    dispatch(clearCart());
                } else {
                    alert("Server issue, please contact support");
                }
            } catch (err) {
                alert("Failed to create deposit, please check with admin");
            }
        } else {
            alert("Please login before checking out");
        }
    };

    const handleAddProduct = (product, color) => {
        try {
            console.log(product)
            if (
                cart.find(
                    (item) =>
                        `${item.title}` ===
                        `${product.title}`
                ) === undefined
            ) {
                dispatch(
                    addProduct({ ...product, activeColor: itemDetails, quantity })
                );
            } else {
                dispatch(
                    addQuantityIcon({
                        ...product,
                        activeColor: itemDetails,
                        quantity,
                    })
                );
            }
        } catch {}
    };

    return (
        <div className="pos">
            <div className="userContainer">
                <div className="checkoutButtons">
                    <div
                        className="salesButton lightyellow"
                        onClick={() => setCashierMode("sales")}
                    >
                        Sales
                    </div>
                </div>
                <div className="usersummaryContainer">
                    <div className="addProductItem fullWidth">
                        <label>Filter Name</label>
                        <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="name"
                            value={name}
                            type="string"
                            placeholder="Name"
                            onChange={handleNameChange}
                        />
                    </div>
                    </div>
                </div>
                <div className="calculator">
                    <div className="cartContainer">
                        <div className="cartTitle">Service</div>

                        <div className="cartTitle">Quantity</div>
                        <div className="cartTitle">Price</div>
                    </div>
                    {cart ? (
                        cart.map((product, i) => (
                            <div className="cartContainer" key={i}>
                                <div className="cartItem capitalize">
                                    {product.color.name}
                                </div>
                                <div className="quantityItem">
                                    <Add
                                        style={{ fontSize: "0.8rem" }}
                                        onClick={() =>
                                            handleQuantity(
                                                product,
                                                1,
                                                product.price,
                                                product.price,
                                                product.color
                                            )
                                        }
                                    />
                                    {product.amount}
                                    <Remove
                                        style={{ fontSize: "0.8rem" }}
                                        onClick={() =>
                                            handleQuantity(
                                                product,
                                                -1,
                                                product.price -
                                                    product.price -
                                                    product.price,
                                                product.price -
                                                    product.price -
                                                    product.price,
                                                product.color
                                            )
                                        }
                                    />
                                </div>
                                <div className="cartItem">
                                    {process.env.REACT_APP_COUNTRY ===
                                    "SINGAPORE"
                                        ? "$"
                                        : "RM"}
                                    {product.price}
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
                <div className="summary">
                    <div className="summarysubtotal">SubTotal </div>
                    <div className="summarysubtotal">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}
                        {total ? NumberFormatter.format(total) : "0"}
                    </div>
                </div>

                <div className="summary">
                    <div className="summarysubtotal">Trade In </div>
                    <div className="summarysubtotal">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}
                        {tradeinamount ? NumberFormatter.format(tradeinamount) : "0"}
                    </div>
                </div>

                <div className="summary depositPadding">
                    <div className="addDiscountItem">
                        <div className="summarysubtotal">Discount</div>
                        <input
                            name="discount"
                            type="number"
                            onChange={handleChange}
                            defaultValue={""}
                        />
                    </div>
                </div>

                {cashierMode === "sales" && (
                    <>
                        <div className="summary totalButton">
                            <div className="summarysubtotal">Total </div>
                            <div className="summarysubtotal">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}
                                {inputs.discount
                                    ? NumberFormatter.format(
                                          total - inputs.discount - tradeinamount
                                      )
                                    : NumberFormatter.format(total - tradeinamount)}
                            </div>
                        </div>

                        <div className="checkoutButtons">
                            <div
                                className="salesButton sales"
                                onClick={handlePosCreateOrder}
                            >
                                Sales{" "}
                            </div>
                        </div>
                        <div className="checkoutButtons">
                            <div
                                className="salesButton cancelfilter"
                                onClick={() =>
                                    setProductFilterCategory({
                                        status: "active",
                                        model: undefined,
                                        brand: undefined,
                                    })
                                }
                            >
                                Reset Brand
                            </div>
                        </div>

                        <div className="checkoutButtons">
                            <div
                                className="salesButton reset"
                                onClick={handleClear}
                            >
                                Full Reset
                            </div>
                        </div>
                        {/* <div
                            className="summary totalButton pointer lightblue"
                            onClick={() => handleWhatsapp()}
                        >
                            Whatsapp
                        </div> */}
                    </>
                )}

                {cashierMode === "deposit" && (
                    <>
                        <div className="summary depositPadding">
                            <div className="addDiscountItem">
                                <div className="summarysubtotal">Deposit</div>
                                <input
                                    name="deposit"
                                    type="number"
                                    onChange={handleChange}
                                    defaultValue={""}
                                />
                            </div>
                        </div>
                        <div className="summary totalButton">
                            <div className="summarysubtotal">Remaining </div>
                            <div className="summarysubtotal">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}
                                {NumberFormatter.format(
                                    inputs.discount && inputs.deposit
                                        ? total -
                                              inputs.discount -
                                              inputs.deposit
                                        : inputs.discount
                                        ? total - inputs.discount
                                        : inputs.deposit
                                        ? total - inputs.deposit
                                        : total
                                )}
                            </div>
                        </div>
                        <div className="">
                            <div
                                className="salesButton deposit"
                                onClick={handlePosDeposit}
                            >
                                Deposit{" "}
                            </div>
                        </div>
                    </>
                )}
            </div>

            {colorpicker && (
                <OverlayContainer>
                    <ModalContainer></ModalContainer>
                    <Overlay>
                        <EmptyModalDetails
                            onClick={() => setColorPicker(false)}
                        ></EmptyModalDetails>
                        <ModalDetails>
                            <ModalItemContainer>
                                <ColorPickerContainer>
                                    <div className="itemDetailsContainer">
                                        <div className="summary">
                                            <div className="addProductItem fullWidth">
                                                <label>Name</label>
                                                <input
                                                    name="name"
                                                    type="text"
                                                    onChange={
                                                        handleSetItemDetails
                                                    }
                                                    defaultValue={itemDetails.name}
                                                />
                                            </div>
                                        </div>

                                        <div className="summary">
                                            <div className="addProductItem fullWidth">
                                                <label>Price</label>
                                                <input
                                                    name="price"
                                                    type="number"
                                                    onChange={
                                                        handleSetItemDetails
                                                    }
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>

                                        <button className="productListEdit mt-2"
                                            onClick={() =>
                                               { handleAddProduct(product, color);
                                                setColorPicker(false);
                                                setItemDetails({
                                                    name: undefined,
                                                    price: undefined,
                                                    type: undefined,
                                                })}
                                            }
                                        >
                                            Add To Cart
                                        </button>
                                    </div>
                                </ColorPickerContainer>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
            <div className="posWrapper">
                <div className="checkoutButtons">
                    <div
                        className="salesButton lightred"
                        onClick={() => setFilterList({ type: "watch" })}
                    >
                        Select Products
                    </div>
                </div>
                <div className="posContainer">
                    {" "}
                    {products ? (
                        products.map((product, i) => {
                            return (
                                <div
                                    key={i}
                                    className="posItem"
                                    onClick={() => {
                                        setColor(product.color);
                                        setProduct(product);
                                        setItemDetails({
                                            name: product.title
                                        })
                                        window.scrollTo(0, 0);
                                        setColorPicker(true);
                                    }}
                                >
                                    
                                    <div className="productDetails">
                                        {product.title}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}
