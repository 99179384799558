import { useState, useEffect, useCallback } from "react";
import { addAffiliate, getProducts } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import { useSelector } from "react-redux";

export default function NewAffiliate() {
    const [inputs, setInputs] = useState({
        prepay: 0,
        discount: 0,
        usage: 99999,
        flatDiscount: 0,
    });
    const [usersData, setUsersData] = useState([]);
    const dispatch = useDispatch();

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "outright"
            : null
    );

    const currentUser = useSelector((state) => state.user.currentUser);

        const getUsers = useCallback(async() => {
            const res = await publicRequest.get("/users/allstaff", {
                params: { isAdmin: true, principle: manager ? currentUser._id : currentUser.principle },
            });
            setUsersData(res.data);;
        }, [currentUser._id, currentUser.principle, manager]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [getUsers, usersData]);

    useEffect(() => {
        getProducts(dispatch, "");
    }, [dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleUserFilter = (e) => {
        setInputs((prev) => {
            return { ...prev, userId: e.value };
        });
    };

    const handleClick = async (e) => {
        const affiliate = { ...inputs };
        if (affiliate.discount > 100) {
            alert("Discount % cannot be more than 100%");
            return;
        }
        if (affiliate.discount === undefined) {
            affiliate.discount = 0;
            return;
        }

        affiliate.discount =
            affiliate.discount === undefined ? affiliate.discount : 0;

        e.preventDefault();
        try {
            addAffiliate(affiliate, dispatch);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New Dealer Code</h1>
                <div className="addProductForm">
                    <div className="addProductItem">
                        <label className="required">Dealer Code</label>
                        <input
                            name="code"
                            type="text"
                            placeholder="Code"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label>User</label>
                        <Select
                            className="borderBlack"
                            options={usersData.map((user, i) => {
                                return {
                                    label: `${user.name} - ${user.phoneNumber} - ${user.email}`,
                                    value: user._id,
                                };
                            })}
                            placeholder={"Full Name / Phone / Email"}
                            onChange={handleUserFilter}
                        />
                    </div>

                    <button onClick={handleClick} className="addProductButton">
                        Create
                    </button>
                </div>
            </div>
        </>
    );
}
