import "./orderList.css";
import Table from "../../components/advancedTable";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
    VerifiedUser,
    Edit,
} from "@material-ui/icons";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOrders, voidOrder, acceptOrder, generateSignature } from "../../redux/apiCalls";
import moment from "moment";
import { FiFile, FiPlus } from "react-icons/fi";
import { publicRequest } from "../../requestMethods";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";
import excel from "../../components/excel/excel";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import createBulk from "../../components/pdf/createBulk";
import { userRequest } from "../../requestMethods";
import { useForm } from "react-hook-form";
import axios from "axios";
import { clearCart, editOrderSeedData } from "../../redux/cartRedux";


const statusOptions = [
    {
        label: "Deposit",
        value: "deposit",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Void",
        value: "void",
    },
    {
        label: "Warranty",
        value: "warranty",
    },
    {
        label: "All",
        value: "",
    },
];

const limitOptions = [
    {
        label: "Basic",
        value: 20,
    },
    {
        label: "Export",
        value: 9999,
    },
];

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const outright = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "outright"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [inputs, setInputs] = useState({
        cashier: superadmin ? undefined : outright ? undefined :  currentUser?._id,
        status: undefined,
        limit: 9999,
        page: 1,
        dateFrom: moment("2024-01-01").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        createdBy: location
            ? location.state
                ? location.state.outlet
                : superadmin ? undefined : principle?._id || currentUser?._id
            : superadmin ? undefined : principle?._id || currentUser?._id
    });

    const [statusMenu, setStatusMenu] = useState(false);
    const [ordersData, setOrdersData] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [acceptMenu, setAcceptMenu] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [suppliersData, setSuppliersData] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [payloadpaymentType, setPayloadPaymentType] = useState("cash");
    const [installmentMenu, setInstallmentMenu] = useState(false);
    const [invoice, setInvoice] = useState(undefined);
    const [galleryModalState, setGalleryModalState] = useState(false);
    // eslint-disable-next-line
    const [paymentType, setPaymentType] = useState([
        {
            label: "Cash",
            value: "cash",
        },
        { value: "onlineTransfer", label: "Online Transfer" },
        { value: "tng", label: "Touch N Go" },
        { value: "cheque", label: "Cheque" },
        { value: "card", label: "Debit Card" },
        { value: "creditCard", label: "Credit Card" },
        { value: "ziip6", label: "ZIIP - 6 months" },
        { value: "ziip12", label: "ZIIP - 12 months" },
        { value: "ziip18", label: "ZIIP - 18 months" },
        { value: "ziip24", label: "ZIIP - 24 months" },
        { value: "others", label: "Others" },
    ]);

    const {
        register: galleryRegister,
        handleSubmit: handleGallerySubmit,
        getValues: getGalleryValues,
        reset: resetGallery,
    } = useForm({
        defaultValues: {
            image: "",
        },
    });

    const handleAddGallery = async () => {
        try {
            const formValues = getGalleryValues();

            let newImage = undefined;

            if (formValues.image.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.image[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.expire
                )
                imageFormData.append(
                    "token",
                    imageresult.data.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.image[0].name} - ${imageresult.data.expire}`
                );
                imageFormData.append("folder", "banners");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await userRequest.post("/orders/addpaymentproof", {
                img: newImage,
                order: modalData._id,
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetGalleryModal();
                alert("Success");
                window.location.reload();
          
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };


    const getAdmins = useCallback(async() => {
        // Your logic for fetching admins
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true, principle:superadmin ? undefined : inputs.createdBy },
        });
        setUsersData(res.data);
    }, [inputs.createdBy, superadmin]);

    const getSuppliers = async () => {
        const res = await publicRequest.get("/users/allsuppliers", {
         
        });
        setSuppliersData(res.data);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getOrders(dispatch, inputs);
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [dispatch, inputs]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    useEffect(() => {
        if (galleryModalState === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [galleryModalState]);

    useEffect(() => {
        if (installmentMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [installmentMenu]);

    const handleInstallment = async (id) => {
        if (currentUser) {
            try {
                const res = await userRequest.put(`/orders/confirm/${id}`);

                if (res.data) {
                    alert("Order completed successfully.");
                    setInstallmentMenu(false);
                    window.location.reload();
                } else {
                    alert("Server issue, please contact support");
                    setInstallmentMenu(false);
                }
            } catch (err) {
                alert(
                    "Please make sure remaining balance is less than amount, and use full payment for full payment."
                );
                setInstallmentMenu(false);
            }
        } else {
            alert("Please login before checking out");
            setInstallmentMenu(false);
        }
    };

    const handlePaymentType = (e) => {
        setPayloadPaymentType(e.value);
    };

    const handleVoid = (id) => {
        voidOrder(id, dispatch);
    };

    const handleAccept = (id) => {
        acceptOrder(id, payloadpaymentType, dispatch);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleInputUser = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, cashier: e.value, createdBy: undefined };
        });
    };

    const handleInputPrinciple = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, createdBy: e.value, cashier: undefined };
        });
    };

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const handleLimitChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, limit: e.value };
        });
    };

    const handleInvoiceChange = (e) => {
        setInputs((prev) => {
            return { ...prev, invoice: e };
        });
        setIsLoaded(false);
    };

    const resetGalleryModal = () => {
        setGalleryModalState(false);
        resetGallery();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleInvoiceChange(invoice);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [invoice]);

    const handleDelayedInvoiceChange = (e) => {
        setInvoice(e.target.value);
    };

    useEffect(() => {
        if (usersData[0] === undefined) {
            getAdmins();
        }
    }, [usersData, getAdmins, dispatch]);

        useEffect(() => {
        if (suppliersData[0] === undefined) {
            getSuppliers();
        }
    }, [suppliersData, dispatch]);

    const productColumns = [
        {
            accessor: "code",
            Header: "Name",
            sortable: false,
            Cell: ({ value }) => <div className="initialPadding">{value}</div>,
        },
        {
            accessor: "quantity",
            Header: "Quantity",
            sortable: false,
            Cell: ({ value }) => <div className="initialPadding">{value}</div>,
        },
        {
            accessor: "price",
            Header: "Unit Price",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">
                    RM {value ? formatter.format(value) : "0"}
                </div>
            ),
        },
        {
            accessor: "amount",
            Header: "Total Price",
            sortable: false,
            Cell: (params) => (
                <div className="initialPadding">
                    RM{" "}
                    {params.row.original.amount
                        ? formatter.format(
                              params.row.original.amount *
                                  params.row.original.price
                          )
                        : "0"}
                </div>
            ),
        },
    ];

    const columns = [
        {
            accessor: "action",
            Header: "Action",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon mr-2"
                                onClick={() => {
                                    setModalData(params.row.original._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        {params.row.original.status === "pending" && (
                            <VerifiedUser
                                className=" pointer icon mr-2"
                                onClick={() => {
                                    const original = params.row.original;
                                    setModalData(original._id);
                                    setInstallmentMenu(true);
                                }}
                            />
                        )}
                        <CloudDownload
                            className="pointer icon mr-2"
                            onClick={() => {
                                createCustomerInvoice(params.row.original);
                            }}
                        />

                    </>
                );
            },
        },
        {
            Header: "Proof of Payment",
            accessor: "paymentproof",
            Cell: ({ row: { original }, value }) => (
                <div className="flex justify-center cursor-pointer">
                     <div
                        className="cursor-pointer mr-2"
                        onClick={async () => {
                            setModalData({
                                ...original,
                            });
                            setGalleryModalState(true);
                        }}
                    >
                        <FiPlus />
                    </div>
                    {value ? (
                        <>
                            <FiFile
                                onClick={() => {
                                    window.open(value, "_blank"); // Open image in a new tab if clicked
                                }}
                            ></FiFile>
                        </>
                    ) : (
                        <></>
                    )}
                   
                </div>
            ),
        },
        {
            accessor: "createdAt",
            Header: "Purchase Date",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            accessor: "product",
            Header: "Products",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon mr-2"
                            onClick={() => {
                                setModalData(params.row.original.products);
                                setStatusMenu(true);
                            }}
                        />
                        <Edit
                            className="pointer icon"
                            onClick={() => {
                                dispatch(clearCart());
                                dispatch(
                                    editOrderSeedData(params.row.original)
                                );
                                history.push(
                                    `/order/${params.row.original._id}`,
                                    {
                                        order: params.row.original,
                                    }
                                );
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "invoiceId",
            Header: "Invoice",
            sortable: false,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "userId",
            Header: "Customer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "affiliateCode",
            Header: "Dealer Code",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.code : ""}</div>
            ),
        },
        {
            accessor: "discount",
            Header: "Discount",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">
                    RM {value ? formatter.format(value) : "0"}
                </div>
            ),
        },
        {
            accessor: "products",
            Header: "Total",
            sortable: false,
            Cell: (params) => {
                let total = 0;
                if (params.row.original.products !== undefined) {
                    for (const product of params.row.original.products) {
                        total = total + product.price * product.amount;
                    }
                }

                const shippingCost = (params.row.original.products.reduce((total, item) => {
                    return total + item.quantity;
                }, 0) * 15);
                return (
                    <div className="capitalize">
                        {params
                            ? params.row.original.country === "singapore"
                                ? "$"
                                : "RM"
                            : "RM"}
                        {formatter.format(
                            params.row.original.deposit &&
                                params.row.original.status === "deposit"
                                ? params.row.original.deposit + shippingCost
                                : params.row.original.deposit &&
                                  params.row.original.status !== "deposit"
                                ? total - params.row.original.discount +  shippingCost
                                : total - params.row.original.discount +  shippingCost
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "cashier",
            Header: "Agent",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "paymentType",
            Header: "Payment Type",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "status",
            Header: "Status",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
    ];

    return (
        <>
        <div className="productList">
        <div className="samerow">
    <div className="addProductItem mt-small">
        <input
            className="inputFilter"
            name="dateFrom"
            value={inputs.dateFrom}
            type="date"
            onChange={(e) => handleDateFrom(e)}
        />
    </div>
    <div className="addProductItem mt-small">
        <input
            className="inputFilter"
            name="dateTo"
            value={inputs.dateTo}
            type="date"
            onChange={(e) => handleDateTo(e)}
        />
    </div>
    <div className="sameRowItem">
        <Select
            options={statusOptions.map((status, i) => {
                return {
                    label: status.label,
                    value: status.value,
                };
            })}
            onChange={handleInputStatus}
            placeholder="Status"
        />
    </div>
    <div className="sameRowItem">
        {admin && !staff && (
            <Select
                options={limitOptions.map((status, i) => {
                    return {
                        label: `${status.label}`,
                        value: status.value,
                    };
                })}
                onChange={handleLimitChange}
                placeholder={"Format"}
            />
        )}
    </div>
</div>

<div className="samerow">
    <div className="addProductItem mt-small">
        <input
            className="inputFilter"
            name="invoice"
            value={invoice}
            type="string"
            placeholder="Invoice"
            onChange={handleDelayedInvoiceChange}
        />
    </div>
    <div className="sameRowItem">
        <Select
            options={usersData.map((status, i) => {
                return {
                    label: `${status.name}-${status.phoneNumber}-${status.username}`,
                    value: status._id,
                };
            })}
            onChange={handleInputUser}
            placeholder="Agents"
            onClick={() =>
                usersData[0] === undefined
                    ? getAdmins()
                    : {}
            }
        />
    </div>
    {superadmin && (
    <div className="sameRowItem">
        <Select
            options={suppliersData.map((status, i) => {
                return {
                    label: `${status.name}-${status.phoneNumber}-${status.username}`,
                    value: status._id,
                };
            })}
            onChange={handleInputPrinciple}
            placeholder="Principles"
            onClick={() =>
                suppliersData[0] === undefined
                    ? getSuppliers()
                    : {}
            }
        />
    </div>
)}
</div>

            <div className="block">
                {superadmin && (
                    <>
                        <button
                            onClick={() => {
                                let payload = [];
                                ordersData.payload.forEach(
                                    (order, i) => {
                                        let productCodes =
                                            order.products
                                                .map(
                                                    (product) =>
                                                        `${product.quantity} x ${product.code} (RM ${product.price})`
                                                )
                                                .join(", ");
                                        payload.push({
                                            No: i + 1,
                                            Invoice:
                                                order.invoiceId,
                                            Customer:
                                                order.userId.name,
                                            PlateNumber:
                                                order.plate,
                                            Car: order.car,
                                            PhoneNumber:
                                                order.userId
                                                    .phoneNumber,
                                            SKU: productCodes,
                                            Retail: Number(
                                                order.amount
                                            ),
                                            Discount: Number(
                                                order.discount
                                            ),
                                            Sales: Number(
                                                order.amount -
                                                    order.discount -
                                                    (order.deposit
                                                        ? order.deposit
                                                        : 0)
                                            ),
                                            Deposit: Number(
                                                order.deposit
                                                    ? order.deposit
                                                    : 0
                                            ),
                                            PaymentType:
                                                order.paymentType,
                                            PurchaseDate: moment(
                                                order.createdAt
                                            ).format("YYYY-MM-DD"),
                                        });
                                    }
                                );
                                excel({
                                    excelData: payload,
                                    fileName: `SSR-Orders-${moment(
                                        inputs.dateFrom
                                    ).format(
                                        "DD-MM-YYYY"
                                    )}-to-${moment(
                                        inputs.dateTo
                                    ).format("DD-MM-YYYY")}`,
                                });
                            }}
                            className="red buttonTop productListButton"
                        >
                            Export
                        </button>

                        {inputs.status === "pending" &&
                            inputs.user !== undefined && (
                                <button
                                    onClick={() => {
                                        const mergeItems = (
                                            items
                                        ) => {
                                            // Initialize the merged result object
                                            const merged = {
                                                address: "-",
                                                amount: 0,
                                                discount: 0,
                                                products: [],
                                                invoiceIds: [],
                                                cashier:
                                                    items[0]
                                                        .cashier, // Assuming cashier is the same for all items
                                                createdAt:
                                                    new Date().toISOString(),
                                                createdBy:
                                                    items[0]
                                                        .createdBy, // Assuming createdBy is the same for all items
                                                paymentType:
                                                    items[0]
                                                        .paymentType, // Assuming paymentType is the same for all items
                                                salesType:
                                                    items[0]
                                                        .salesType, // Assuming salesType is the same for all items
                                                status: items[0]
                                                    .status, // Assuming status is the same for all items
                                                updatedAt:
                                                    new Date().toISOString(),
                                                userId: items[0]
                                                    .userId, // Assuming userId is the same for all items
                                                __v: 0,
                                                _id: `merged_${Date.now()}`, // Generating a new ID for the merged item
                                            };

                                            // Loop through each item and merge the fields
                                            items.forEach(
                                                ({
                                                    amount,
                                                    discount,
                                                    products,
                                                    createdAt,
                                                    invoiceId,
                                                    plate,
                                                }) => {
                                                    merged.amount +=
                                                        amount;
                                                    merged.discount +=
                                                        discount;
                                                    products.forEach(
                                                        (
                                                            product
                                                        ) => {
                                                            product.productdate =
                                                                moment(
                                                                    createdAt
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                );
                                                            product.invoiceId =
                                                                invoiceId;
                                                            product.plate =
                                                                plate;
                                                        }
                                                    );

                                                    merged.products =
                                                        [
                                                            ...merged.products,
                                                            ...products,
                                                        ];
                                                }
                                            );

                                            return merged;
                                        };

                                        const mergedItem =
                                            mergeItems(
                                                ordersData.payload
                                            );

                                        createBulk(mergedItem);
                                    }}
                                    className="blue buttonTop productListButton"
                                >
                                    Customer Statement
                                </button>
                            )}
                    </>
                )}
            </div>

            <div className="reportContainer">
                <div className="featuredReport blue">
                    <span className="featuredTitle">
                        Total Orders:
                    </span>
                    <div className="featuredReportContainer">
                        <span className="featuredMoney">
                            {" "}
                            {ordersData.totalPages}
                        </span>
                    </div>
                </div>

                <div className="featuredReport red">
                    <span className="featuredTitle">
                        Total Amount:
                    </span>
                    <div className="featuredReportContainer">
                        <span className="featuredMoney">
                            {" "}
                            RM{" "}
                            {formatter.format(
                                ordersData.totalAmount
                            )}
                        </span>
                    </div>
                </div>
            </div>
                                <Table
                    data={ordersData ? ordersData.payload : []}
                    columns={columns}
                    pageCount={
                        ordersData
                            ? Math.floor(
                                  ordersData.totalPages / 20
                              ) + 2
                            : 1
                    }
                    totalCount={ordersData.totalPages}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                />
        </div>

        {statusMenu && (
            <div className="overlayContainer">
                <div className="bigcenter">
                    <div
                        className="close"
                        onClick={() => setStatusMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="updatemodalContainer">
                        <div className="updateOrder">
                            <h1 className="updateProductTitle text-xl font-semibold">
                                Products({modalData.length})
                            </h1>
                            <Table
                                data={modalData}
                                columns={productColumns}
                                hidePagination={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )}

        {voidMenu && (
            <div className="overlayContainer">
                <div className="center">
                    <div
                        className="close"
                        onClick={() => setVoidMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="actionContainer">
                        <div className="updateOrder">
                            <h3 className="updateProductTitle">
                                Confirm Void Order?
                            </h3>
                            <div className="buttonContainer">
                                <button
                                    className="confirm pointer green"
                                    onClick={() => {
                                        handleVoid(modalData);
                                        setVoidMenu(false);
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="cancel pointer red"
                                    onClick={() => {
                                        setVoidMenu(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {acceptMenu && (
            <div className="overlayContainer">
                <div className="center">
                    <div
                        className="close"
                        onClick={() => setAcceptMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="actionContainer">
                        <div className="updateOrder">
                            <h3 className="updateProductTitle">
                                Confirm Accept Order?
                            </h3>
                            <div className="">
                                <label className="flex justify-start text-lynch">
                                    Payment Type
                                </label>
                                <Select
                                    options={paymentType.map(
                                        (paymentType, i) => {
                                            return {
                                                label: paymentType.label,
                                                value: paymentType.value,
                                            };
                                        }
                                    )}
                                    onChange={handlePaymentType}
                                />
                            </div>
                            <div className="buttonContainer">
                                <button
                                    className="confirm pointer green"
                                    onClick={() => {
                                        handleAccept(modalData);
                                        setAcceptMenu(false);
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="cancel pointer red"
                                    onClick={() => {
                                        setAcceptMenu(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {installmentMenu && (
            <div className="overlayContainer">
                <div className="center">
                    <div
                        className="close"
                        onClick={() => setInstallmentMenu(false)}
                    >
                        <Close></Close>
                    </div>
                    <div className="actionContainer">
                        <div className="updateOrder">
                            <h3 className="updateProductTitle">
                                Confirm Completed & Ship Out to
                                Customer
                            </h3>
                            <div className="buttonContainer">
                                <button
                                    className="confirm pointer green"
                                    onClick={() => {
                                        handleInstallment(
                                            modalData
                                        );
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="cancel pointer red"
                                    onClick={() => {
                                        setInstallmentMenu(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}

{galleryModalState && (
     <div className="overlayContainer">
       <div className="actionContainer">
            <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                <div className="mb-4 text-3xl text-black font-bold">
                    Upload Gallery
                </div>
                <form
                    onSubmit={handleGallerySubmit(handleAddGallery)}
                    className="lg:w-104"
                >
                    <div>
                        <div className="my-2 text-sm text-lynch font-medium">
                            Image
                        </div>
                        <input
                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs "border-gray-300"`}
                            name="image"
                            {...galleryRegister("image")}
                            type="file"
                        />
                    </div>
                    <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </div>
)}
    </>
    );
}
