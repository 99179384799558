import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReport } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";
import moment from "moment";
import excel from "../../components/excel/excel";
import "./reporting.css";

export default function Report() {
    const dispatch = useDispatch();
    const salesReport = useSelector((state) => state.order.order);
    const currentUser = useSelector((state) => state.user.currentUser);
    const isFetching = useSelector((state) => state.order.isFetching);

    const TwoDecimalFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "superadmin" : null
    );


    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    //eslint-disable-next-line
    const [depositAmount, setDepositAmount] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [profitAmount, setProfitAmount] = useState(0);
    const [inputs, setInputs] = useState({
            principle: principle
            ? principle
            : superadmin 
            ? undefined
            : currentUser._id,
            createdBy: principle ? currentUser._id : superadmin ? undefined : undefined,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
    });

    const getAdmin = async () => {
        const res =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? await publicRequest.get("/users/admin", {
                      params: { isAdmin: true },
                  })
                : await publicRequest.get("/users/superadmin", {
                      params: { isAdmin: true },
                  });
        setUsersData(res.data);
    };

    const handleDateFrom = (e) => {
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };
    const handleDateTo = (e) => {
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getReport(dispatch, inputs);
            setTotalAmount(0);
            setDepositAmount(0);
            setCommissionAmount(0);
            setProfitAmount(0);
            setModalData(res);
            if (res) {
                setIsLoaded(true);
                for (const item of res) {
                    if (item.outlet === "HAOFA Tourbillon Singapore") {
                    } else if (item.outlet !== "HAOFA Tourbillon Singapore"){
                        setTotalAmount((prev) => prev + item.monthlySales);
                        setDepositAmount((prev) => prev + item.monthlyDeposit);
                        setCommissionAmount((prev) => prev + item.commission);
                        setProfitAmount((prev) => prev + item.profit);
                    }
                }
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getAdmin();
        }
    }, [usersData]);

    const columns = [
        {
            field: "outlet",
            headerName: "Dealer",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "salesAmount",
            headerName: "Total Units",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : 0}</div>
            ),
        },
        {
            field: "totalSales",
            headerName: "Total Sales",
            width: 300,
            sortable: false,
            renderCell: (params) => (
                <div className="initialPadding">
                    {" "}
                    {params
                        ? `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(
                              params.row.totalSales
                          )}`
                        : `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(0)}`}
                </div>
            ),
        },
    
        {
            field: "commission",
            headerName: "Commission",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => 
                <div className="initialPadding">RM {value ? TwoDecimalFormatter.format(value) : 0}</div>
        },
    ];

    return (
        <>
            <div className="productList">
                <div className="samerow">
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateFrom"
                            value={inputs.dateFrom}
                            type="date"
                            onChange={(e) => handleDateFrom(e)}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateTo"
                            value={inputs.dateTo}
                            type="date"
                            onChange={(e) => handleDateTo(e)}
                        />
                    </div>
                </div>
                
                <div className="block">
                    <button
                        onClick={() => {
                            excel({
                                excelData: salesReport,
                                fileName: `Haofa-Sales-${moment(
                                    inputs.dateFrom
                                ).format("DD-MM-YYYY")}-to-${moment(
                                    inputs.dateTo
                                ).format("DD-MM-YYYY")}`,
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                </div>

                <div className="reportContainer">
                    <div className="featuredReport red">
                        <span className="featuredTitle">Total Amount:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">   {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}{" "}
                                {TwoDecimalFormatter.format(totalAmount)}</span>
                        </div>
                    </div>
                    <div className="featuredReport blue">
                        <span className="featuredTitle">Total Commission:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}{" "}
                                {TwoDecimalFormatter.format(commissionAmount)}
                            </span>
                        </div>
                    </div>

                   {superadmin && (
                     <div className="featuredReport blue">
                     <span className="featuredTitle">Total Profit:</span>
                     <div className="featuredReportContainer">
                         <span className="featuredMoney">
                             {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                 ? "$"
                                 : "RM"}{" "}
                             {TwoDecimalFormatter.format(profitAmount)}
                         </span>
                     </div>
                 </div>
                    )}
                   
                </div>
                {isLoaded === true && (
                    <DataGrid
                        rows={isFetching === false ? modalData : []}
                        disableSelectionOnClick
                        columns={columns}
                        pageSize={100}
                        disableColumnMenu
                    />
                )}

            </div>
        </>
    );
}
